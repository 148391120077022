import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faBan,
    faBars,
    faBell,
    faCaretDown,
    faCaretRight,
    faChalkboardUser,
    faChartLine,
    faChartSimple,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCircleDot,
    faCirclePlay,
    faClock,
    faCode,
    faCog,
    faCogs,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faExternalLink,
    faExternalLinkSquareAlt,
    faFeatherPointed,
    faFile,
    faFlag,
    faFlagCheckered,
    faGripVertical,
    faHandshakeAngle,
    faHome,
    faImage,
    faKey,
    faLanguage,
    faLink,
    faLock,
    faMapSigns,
    faNewspaper,
    faPause,
    faPlus,
    faPlusCircle,
    faRotate,
    faSave,
    faSearch,
    faShieldHalved,
    faSignOutAlt,
    faSoccerBall,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faStar,
    faSync,
    faTable,
    faTags,
    faThList,
    faTimes,
    faToggleOff,
    faToggleOn,
    faTrash,
    faTriangleExclamation,
    faTruck,
    faUser,
    faUserEdit,
    faUserPlus,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

import {faCheckCircle,} from '@fortawesome/free-regular-svg-icons';

library.add(
    faSpinner,
    faHome,
    faClock,
    faBell,
    faCheck,
    faCheckCircle,
    faUsers,
    faUser,
    faSignOutAlt,
    faSearch,
    faFile,
    faThList,
    faBars,
    faUserPlus,
    faArchive,
    faUserEdit,
    faCaretRight,
    faLanguage,
    faExclamationCircle,
    faCaretDown,
    faCogs,
    faSort,
    faSortUp,
    faSortDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPlus,
    faPlusCircle,
    faEdit,
    faToggleOn,
    faToggleOff,
    faSave,
    faTrash,
    faTimes,
    faNewspaper,
    faLock,
    faChevronDown,
    faChevronUp,
    faTruck,
    faCog,
    faTags,
    faExternalLinkSquareAlt,
    faMapSigns,
    faStar,
    faChartLine,
    faChartSimple,
    faCode,
    faKey,
    faGripVertical,
    faSync,
    faImage,
    faLink,
    faEnvelope,
    faTable,
    faSoccerBall,
    faHandshakeAngle,
    faShieldHalved,
    faBan,
    faPause,
    faRotate,
    faCirclePlay,
    faFlagCheckered,
    faExternalLink,
    faFile,
    faTriangleExclamation,
    faFlag,
    faFeatherPointed,
    faCircleDot,
    faChalkboardUser
);
